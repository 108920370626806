import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    Carousel,
    DarkMode,
    Divider,
    DeviceType,
    LightMode,
    Testimonial,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
} from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import socialPreview from '../../../static/social-previews/flashnews.png';
import Seo from '../../components/SEO';
import { ReferenceCategory } from '../../constants';

const colors = {
    orange: '#F85A25',
    lightGray: '#F2EFF0',
    beige: '#FFE1D7',
};

const CASE_ID = 'flashnews';
const MODULE_NAME = 'flashnews';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const team = [
    { department: 'references.case.team.management', names: ['Jan Vítek', 'Marek Mouček'] },
    { department: 'references.case.team.design', names: ['Anna Mescheryakova'] },
    {
        department: 'references.case.team.backend',
        names: ['Jaroslav Šmolík', 'Jiří Šmolík', 'Lukáš Loukota'],
    },
    { department: 'references.case.team.web', names: ['Jiří Čermák', 'Marek Janča', 'Filip Kubík', 'Jakub Baierl'] },
    {
        department: 'references.case.team.android',
        names: ['Jan Mottl', 'Andrew Fitzsimons'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Karel Leinhäupl', 'Marek Fořt'],
    },
    {
        department: 'references.case.team.devops',
        names: ['Martin Beránek', 'Tibor Tribus'],
    },
    { department: 'references.case.team.testing', names: ['Julia Furmanyuk'] },
];

const FlashNews = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            headerTheme="dark"
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/01.png`,
                    style: { height: '80%', width: '80%', marginTop: '2rem', marginBottom: '3rem' },
                },
            }}
            backgroundImage={{
                src: `${IMAGE_PATH}/herovector.png`,
                imgStyle: { objectFit: 'contain' },
            }}
            background={colors.beige}
            textColor={'#000000'}
            extend={{
                mainContainer: () => ({
                    tabletMax: {
                        height: 'auto',
                    },
                }),
                container: () => ({
                    tabletMax: {
                        height: 'auto',
                    },
                }),
                titleContainer: () => ({
                    tablet: {
                        marginTop: '10%',
                        paddingTop: 0,
                    },
                }),
            }}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                outputs: [
                    'references.case.output.mobile',
                    'references.case.output.web',
                    'references.case.output.design',
                    'references.case.output.backend',
                    'references.case.output.testing',
                    'references.case.output.management',
                ],
                category: ReferenceCategory.DIGITALIZATION,
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.ONE_HALF}
            fullHeight
            content={[
                {
                    background: colors.lightGray,
                    image: {
                        src: `${IMAGE_PATH}/02.png`,
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                    extend: {
                        imageContainer: {
                            paddingTop: '12%',
                        },
                    },
                },
                {
                    background: colors.orange,
                    text: [`case.${CASE_ID}.galleryTitles.darkMode`, `case.${CASE_ID}.galleryTitles.lightMode`],
                    textColor: '#FFFFFF',
                    type: Gallery.GALLERY_ITEM_TYPE.SWITCH,
                    buttons: [
                        {
                            children: <DarkMode />,
                        },
                        {
                            children: <LightMode />,
                        },
                    ],
                    items: [
                        {
                            image: {
                                src: `${IMAGE_PATH}/03_dark.png`,
                                style: {
                                    display: 'block',
                                    margin: 'auto',
                                    objectFit: 'contain',
                                    width: '40%',
                                },
                            },
                        },
                        {
                            image: {
                                src: `${IMAGE_PATH}/03_light.png`,
                                style: {
                                    display: 'block',
                                    margin: 'auto',
                                    objectFit: 'contain',
                                    width: '40%',
                                },
                            },
                        },
                    ],
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
        />
        <Divider height={'1.25rem'} />
        <Gallery
            fullHeight
            headerTheme="light"
            layout={GalleryLayout.TWO_THIRDS}
            content={[
                {
                    background: colors.beige,
                    image: {
                        src: `${IMAGE_PATH}/04.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center top',
                        },
                    },
                },
                {
                    background: colors.lightGray,
                    image: {
                        src: `${IMAGE_PATH}/05.png`,
                        style: {
                            objectFit: 'cover',
                            display: 'block',
                            width: '100%',
                        },
                    },
                    extend: {
                        imageContainer: {
                            paddingBottom: 20,
                            paddingLeft: 20,
                            paddingTop: 20,

                            '& .gatsby-image-wrapper': {
                                maxHeight: 'unset',
                                minHeight: 'unset',
                            },
                        },
                    },
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <Carousel
            headerTheme="dark"
            deviceType={DeviceType.DESKTOP}
            background={colors.orange}
            description={`case.${CASE_ID}.galleryTitles.webApp`}
            slides={[
                {
                    image: {
                        src: `${IMAGE_PATH}/06_01.png`,
                        alt: `case.${CASE_ID}.galleryTitles.webApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/06_02.png`,
                        alt: `case.${CASE_ID}.galleryTitles.webApp`,
                    },
                },
                {
                    image: {
                        src: `${IMAGE_PATH}/06_03.png`,
                        alt: `case.${CASE_ID}.galleryTitles.webApp`,
                    },
                },
            ]}
        />

        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
        />
        <Gallery
            headerTheme={'light'}
            layout={GalleryLayout.ONE_HALF}
            content={[
                {
                    background: colors.lightGray,
                    textColor: '#FFFFFF',

                    image: {
                        src: `${IMAGE_PATH}/07.png`,
                        style: { height: '100%', display: 'block', margin: 'auto' },
                    },
                    extend: {
                        imageContainer: {
                            paddingTop: '12%',
                        },
                    },
                },
                {
                    background: colors.lightGray,
                    image: {
                        src: `${IMAGE_PATH}/08.png`,
                        style: {
                            height: '100%',
                            display: 'block',
                            margin: 'auto',
                        },
                        imgStyle: {
                            maxWidth: 'unset',
                        },
                    },
                    extend: {
                        imageContainer: {
                            paddingTop: '12%',
                        },
                    },
                },
            ]}
            fullHeight
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
        />
        <Divider height={'1.25rem'} />
        <Gallery
            fullHeight
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.orange,
                    image: {
                        src: `${IMAGE_PATH}/09.png`,
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                    extend: {
                        imageContainer: {
                            paddingTop: '5%',
                        },
                    },
                },
            ]}
        />
        <Testimonial
            headerTheme="dark"
            background="#F9F9F9"
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/img_person_flashnews.jpg`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        />
        <Team headerTheme="dark" content={team} />
    </>
);

export default FlashNews;
